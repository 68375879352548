@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logo-shiba {   
    width: 17.5em;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s, -webkit-transform 1s;
}

.logo-shiba:hover   {
  -webkit-transform: perspective(200px) translateZ(20px);
          transform: perspective(200px) translateZ(20px);
}

video {
    position: absolute;
    
   
    width: 100%;
    height: 160%;
    object-fit: cover;
    z-index: -1;
  }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.verde {
  color: green;
}

.rojo {
  color: red;
}

@font-face {
    font-family: helvari;
    src: url(/static/media/helvari.4e1f14f0.ttf);
}
@font-face {
    font-family: beams;
    src: url(/static/media/Beams.ad1ba836.ttf);
}
@font-face {
    font-family: inter-regular;
    src: url(/static/media/Inter-Regular.76e872bc.otf);
}
@font-face {
    font-family: inter-black;
    src: url(/static/media/Inter-Black.e6fef702.otf);
}
@font-face {
    font-family: inter-bold;
    src: url(/static/media/Inter-Bold.d759e235.otf);
}
@font-face {
    font-family: inter-light;
    src: url(/static/media/Inter-Light.d7019947.otf);
}
@font-face {
    font-family: inter-thin;
    src: url(/static/media/Inter-Thin.72869267.otf);
}
html, body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #000000; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(0, 0, 0); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(255, 255, 255); 
  }
p {
    text-align: center;
}
body {
    background-color: black;
    color: #ffffff;
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    
}
header {
    width: 100%;
    padding-top: 30px;
    
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(0deg, transparent, rgb(0, 0, 0));
    z-index: 5;
}



@media screen and (max-width: 968px) {
  header  {
    justify-content: space-between;
  }

  h3  {
    font-size: 100%;
  }

  main section .description > p {
    width: 30px;
    font-size: 1px;
}
  
}


















header ul {
    padding: 0;
    margin: 0;
    display: flex;
    grid-gap: 40px;
    gap: 40px;
    align-items: center;
   
    margin: 0 auto;
}
header ul li {
    list-style: none;
   
}

.fa-globe, .fa-twitter   {
  color: white;
  font-size: 1.5em;
  transition: all 400ms;
}

.fa-globe:hover, .fa-twitter:hover   {
  color: #51A6F6;
} 

.fa-cannabis  {
  color: green;
}

header ul li a {
    color: #ffffff;
    text-decoration: none;
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;
  
    
}
header ul li a:hover {
    text-decoration: underline;
}
header div#right-side ul li a {
    border: 1px solid #ffffff;
    padding: 10px 20px;
    transition: color linear .3s;
}
header div#right-side ul li a:hover {
    background-color: #ffffff;
    color: #000000;
    text-decoration: none;
}
main section {
    width: 100%;
    padding-top: 150px;
}
main section#title-section {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
main section#title-section h1 {
    margin: 0;
    padding: 0;
    font-family: 'beams';
    font-size: 50px;
    text-transform: uppercase;
}



main section#title-section a#scroll-to-adventure-action {
    color: #ffffff;
    position: absolute;
    z-index: 2;
    bottom: 30px;
    font-family: 'helvari';
    font-size: 20px;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
}
main section#title-section a#scroll-to-adventure-action:hover {
    text-decoration: underline;
}
main section#intro-background-section {
    width: 100vw;
    height: 100vh;
    position: relative;
    padding: 0;
}
main section#intro-background-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
main section#the-story-section img {
    width: 60%;
   
    display: flex;
    margin: auto;
    
    margin-top: 150px;
}
main section#carousell-section {
    height: auto;
    padding-top: 0;
}



/* ROADMAP */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

main section#roadmap-section {
    padding: 25px 0 0 0;
}
main section#roadmap-section #roadmap-steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    grid-gap: 50px;
    gap: 50px;
    z-index: 200;

}

a:hover   {
  text-decoration: none;
  list-style: none;
  color: white;
}

html {
  font-family: "Montserrat";
}



.carousel   {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-item img  {
  width: 500px;
}

/* ROADMAP */

.h3-faq     {
    font-size: 50px;
}

main section#guyscoin-section #guyscoin-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
main section#guyscoin-section #guyscoin-section-content img {
    width: 400px;
    z-index: 10;
    --webkit-animation: spin 1s linear infinite;
    -webkit-animation: spin 10s linear infinite;
            animation: spin 10s linear infinite;
}

@media  screen and (max-width: 968px) {
  main section#guyscoin-section #guyscoin-section-content img   {
    width: 70vw;
  }
}

/* */

@-webkit-keyframes spin     {
    100%    {-webkit-transform: rotate(360deg);}
}

@keyframes spin     {
    100%    {-webkit-transform: rotate(360deg);}
}




main section#guyscoin-section #guyscoin-section-content h5 {
    font-family: 'inter-regular';
    text-transform: uppercase;
    font-size: 30px;
    background-color: #1b1b1a;
    border-radius: 10px;
    padding: 5px 15px;
}
main section#guyscoin-section #guyscoin-section-content h3 {
    /* font-family: 'inter-black'; */
    font-family: 'Courier New', Courier, monospace;
    text-transform: uppercase;
    font-size: 60px;
    margin: 15px;
    line-height: 1;
}
main section#guyscoin-section #guyscoin-section-content h3 span.small-title {
    font-size: 40px;
}
main section#guyscoin-section #guyscoin-section-content p {
    /* font-family: 'inter-regular'; */
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
    width: 300px;
}
#participation-section {
    display:flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: 25px 0 0 0;
}
#participation-section img{
    width: 100%;
}
main section#participation-section h3 {
    text-align: center;
    font-family: 'inter-black';
    text-transform: uppercase;
    font-size: 70px;
    color: white;
    margin: 50px 0 15px 0;
    line-height: 1;
}
main section#participation-section h3 span.small-title {
    font-size: 40px;
}
main section#collaborator-section {

    width: 100%;
    display: flex;
    flex-wrap: nowrap;
}
main section#collaborators-section #participation-section-content {
    display: flex;
    justify-content: center;
    grid-gap: 50px;
    gap: 50px;
    overflow: hidden;
}
.collaborator-item {
    position: relative;
    -webkit-animation-duration: 30s;
            animation-duration: 30s;
    -webkit-animation-name: collaboratorAnimation;
            animation-name: collaboratorAnimation;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    text-align: center;
}
main section#collaborators-section #participation-section-content article h4 {
    font-family: 'inter-regular';
    font-size: 24px;
    margin: 0;
}
main section#collaborators-section #participation-section-content article p {
    font-family: 'inter-light';
    font-size: 18px;
    margin: 5px;
}

@-webkit-keyframes collaboratorAnimation {
    100%   {right: 1000px;}
    0% {right: 0px;}
  }

@keyframes collaboratorAnimation {
    100%   {right: 1000px;}
    0% {right: 0px;}
  }


main section .title {
    width: 100%;
    text-align: center;
    /* font-family: 'inter-black'; */
    font-family: 'Courier New', Courier, monospace;
    font-size: 60px;
    margin-bottom: 50px;
}
main section .title h3 {
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    margin-top: 1em;
}
main section .title h3:nth-child(2) {
    -webkit-transform: scale(1, -1);
            transform: scale(1, -1);
    margin-top: -35px;
    color: rgb(255, 255, 255);
}
main section .description {
    display: flex;
    /* font-family: 'inter-regular'; */
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    justify-content: center;
}
main section .description > p {
    width: 700px;
    font-size: 18px;
}
.discord-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}
.discord-description article {
  margin: 15px;
}

footer {
    padding:  200px 0 20px 0  ;
}
footer div#footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
footer div#footer-content > img {
    width: 100px;
}
footer div#footer-content div#footer-info {
    display: flex;
    margin-top: 50px;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
footer div#footer-content div#footer-info span {
    color: #ffffff;
    font-family: 'Courier New', Courier, monospace;
    font-size: 18px;
}


/* BOTON DISCORD PRUEBA */

/* 
button {
    --yellow: #;
    font-size: 15px;
    padding: 0.7em 2.7em;
    letter-spacing: 0.06em;
    position: relative;
    font-family: inter-regular;
    border-radius: 0.6em;
    overflow: hidden;
    transition: all 0.3s;
    line-height: 1.4em;
    border: 2px solid #f8ff36;
    background: linear-gradient(to right, rgba(253, 245, 27, 0.1) 1%, transparent 40%,transparent 60% , rgba(249, 253, 27, 0.1) 100%);
    color: var(--green);
    cursor: pointer;
    box-shadow: inset 0 0 10px rgba(223, 253, 27, 0.4), 0 0 9px 3px rgba(249, 253, 27, 0.1);
   }
   
   button:hover {
    color: #f8ff36 ;
    box-shadow: inset 0 0 10px rgba(176, 203, 69, 0.6), 0 0 9px 3px rgba(223, 253, 27, 0.2);
   }
   
   button:before {
    content: "";
    position: absolute;
    left: -4em;
    width: 4em;
    height: 100%;
    top: 0;
    transition: transform .4s ease-in-out;
    background: linear-gradient(to right, transparent 1%, rgba(227, 253, 27, 0.1) 40%,rgba(227, 253, 27, 0.1) 60% , transparent 100%);
   }
   
   button:hover:before {
    transform: translateX(15em);
   } */

/* From uiverse.io by @satyamchaudharydev */
/* === removing default button style ===*/
.button {
  margin: 15px;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}

/* button styling */
.button {
  --border-right: 6px;
  --text-stroke-color: rgba(255,255,255,0.6);
  --animation-color: #6366f1;
  --fs-size: 2em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: "Arial";
  position: relative;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.button:hover .hover-text {
  width: 100%;
  -webkit-filter: drop-shadow(0 0 15px #6366f1);
          filter: drop-shadow(0 0 15px #6366f1)
}
/* */



/*CARDS TEAM */
.img-member  {
  width: 200px;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  transition: 400ms;
}

.img-member:hover   {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}
.member   {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 2em;
  font-family: 'Courier New', Courier, monospace;
}

.team   {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}


.member1    {
    display: flex;
    flex-direction: column;
    
}
.member-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.member-description h6 {   
    font-family: inter-regular;
    font-size: 20px;
    margin: 5px;
}

.member-description p {   
    font-family: inter-regular;
    font-size: 16px;
    margin: 2px;
}






/*CARDS TEAM */



.mint-info  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-items: stretch;
}
.mint-second-tittle {
    margin: 0;
}
.mint1 {
    padding: 20px;
    border-radius: 15px;
    width: 200px;
    background: linear-gradient(0deg, rgba(47,41,52,1) 0%, rgba(48,41,53,1) 89%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mint-info .mint1:nth-child(1) {
    background: linear-gradient(0deg, rgb(99 102 241) 0%, rgb(69 71 178) 89%);
    transition: all 2s ease-out;
}

.mint-weekend   {
    -webkit-writing-mode: vertical-rl;
            writing-mode: vertical-rl;
    -webkit-text-orientation: upright;
            text-orientation: upright;
    flex-wrap: wrap;
    display: block;
    background: rgb(47,41,52);
    background: linear-gradient(0deg, rgba(47,41,52,1) 0%, rgba(48,41,53,1) 89%);
    border-radius: 10px;
    padding: 5px;
}


.mint-sub-title     {
    font-weight: 200;
    font-size: 20px;
    margin: 10px 0 0 0;
}
.counter-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 1.25rem 0;
}

.counter-item {
    width: 5rem;
    padding: 1.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
    margin: 0.5rem;
    border-radius: 0.75rem;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgb(99 102 241 / 0.5);
    --tw-shadow: var(--tw-shadow-colored);

}
.galery-carrousel {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    grid-row: 4 / 5;
    grid-column: 1 / 2;
    box-shadow: 10px 0 20px #0005;
    z-index: 10;
    overflow: hidden;
}

.gallery-item {
    width: 500px;
    height: 500px;
    position: relative;
    /* animation-duration: 10s;
    animation-name: example;
    animation-timing-function: linear;
    animation-iteration-count: infinite; */
    margin: 70px 20px 70px 20px;
    box-shadow: #fff3 -2px 1px 20px 0px;
}
#collab-tittle {
    font-size: 0.6em;
    margin: 150px 0 0 0;
}

/* MINT INFO */


/*CAROUSEL */

#carousel   {
  overflow: hidden;
}

.slider     {
 
    height: 250px;
    margin: auto;
    position: relative;
    width: 90%;
    display: grid;
    place-items: center;
}

.slider-track { 
    display: flex;

    width: 100%;
    -webkit-animation: scroll 35s linear infinite;
            animation: scroll 35s linear infinite;
   
}

@-webkit-keyframes scroll   {
    0%  {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100%    {
        -webkit-transform: translateX(calc(-250px * 8));
                transform: translateX(calc(-250px * 8));
    }
}

@keyframes scroll   {
    0%  {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
    100%    {
        -webkit-transform: translateX(calc(-250px * 8));
                transform: translateX(calc(-250px * 8));
    }
}

.slide  {
   height: 200px;
   width: 250px;
    display: flex;
    align-items: center;
    padding: 15px;
    -webkit-perspective: 100px;
            perspective: 100px;
}

.slider img     {
    width: 100%;
  
    transition: -webkit-transform 1s;
  
    transition: transform 1s;
  
    transition: transform 1s, -webkit-transform 1s;
}

.slider img:hover   {
    -webkit-transform: translateZ(20px);
            transform: translateZ(20px);
}

@media screen and (max-width: 968px)   {
  .carousel  { 
        
        height: 50px
  }

  header ul   {
    grid-gap: 15px;
    gap: 15px;
  }
  
  header ul li a  {
    grid-gap: 20px;
    gap: 20px;
    font-size: 0.8em;
  }

  main section#the-story-section img {
    width: 100vw;
}

main section .title h3:nth-child(2) { 
  margin-top: -20px;
}

}



/* CAROUSEL */


.a-whitepaper   {
  text-decoration: none;
  color: #5F9C56;

}

.a-whitepaper:hover   {
  color: white;
}
/* FAQ */


 .left-content 
  {
   
    text-align: center;
    font-family: inter-regular;
    font-size: 20px;
    
    padding-bottom: 1rem;
 }
/* FAQ */



@media (max-width: 480px) {

    #roadmap-steps {
        flex-direction: column;
        align-items: center;
    }

    .team   {
        display: flex;
        flex-direction: column;
    }


    .whitepaper, .shadow   {
      font-size: 0.8em;
    }


    main section .description > p   {
      width: 300px;
    }


    main section#guyscoin-section #guyscoin-section-content h3  {
      font-size: 44px;
    }

    


    header {
        display: none;
    }
    main section#title-section h1 {
        font-size: 25px;
    }
    main section .title{
        font-size: 40px;
    }
    main section .title h3:nth-child(2) {
        margin-top: -16px;
    }
    main section .description > p{
        width: 350px;
        font-size: 15px;
    }
    .gallery-item{
        width: 300px;
        height: 300px;
    }
    main section#roadmap-section #roadmap-steps {
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
    
    }
    main section#guyscoin-section {
        padding: 15px 0 0 0;
        overflow: hidden;
    }
    main section#guyscoin-section #guyscoin-section-content img {
        width: 400px;
    }
    main section#guyscoin-section #guyscoin-section-content h3 {
        font-size: 40px;
    }
    main section#guyscoin-section #guyscoin-section-content h3 span.small-title {
        font-size: 20px;
    }
    main section#guyscoin-section #guyscoin-section-content p {
        width: 350px;
        font-size: 15px;
    }
    #participation-section {
        padding: 15px 0 0 0;
    }
    .mint-section {
        padding: 50px 0;
    }
    .mint-info {
        flex-direction: column;
    }
    .mint-weekend{
        -webkit-writing-mode: horizontal-tb;
                writing-mode: horizontal-tb;
    }
    .counter-item {
        width: 1.7rem;
    padding: 1.3rem;
    --tw-bg-opacity: 1;
    background-color: rgb(99 102 241 / var(--tw-bg-opacity));
    margin: 0.5rem;
    border-radius: 0.75rem;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-shadow-color: rgb(99 102 241 / 0.5);
    --tw-shadow: var(--tw-shadow-colored);
    text-align: center;
    }
    .main section#collaborators-section #participation-section-content {
        grid-gap: 20px;
        grid-gap: 20px;
        gap: 20px;
    }

}


@media (max-width: 1310px) {
    header {
        width: 90%;
    }
    header ul {
        grid-gap: 20px;
        gap: 20px;
    }
}

@media (min-width: 1000px) {}


.swiper-pagination-bullet   {
  background-color: white !important;
}

.img-slider   {
  width: 100%;
}

.swiper   {
  width: 80%;
}

.swiper-backface-hidden .swiper-slide {

  width: 75%; }
